<template>
  <div class="home-page">
    <van-swipe class="home-banner" :autoplay="3000" indicator-color="white">
      <van-swipe-item
        v-for="item in posterList"
        :key="item.id"
        @click="handleClick(item)"
      >
        <img
          class="poster-img"
          :src="item.imageUrl"
          :alt="item.title"
          srcset=""
        />
      </van-swipe-item>
    </van-swipe>
    <div class="home-columns">
      <div class="home-columns-item" v-for="item in columnsList" :key="item.id">
        <img
          @click="handleClick(item)"
          class="home-columns-item-img"
          :src="item.imageUrl"
          :alt="item.title"
          srcset=""
        />
        <span class="home-columns-item-text">{{ item.title }}</span>
      </div>
    </div>

    <div class="home-group" v-for="item in groupList" :key="item.id">
      <p class="home-group-title">{{ item.articleType }}</p>
      <a
        class="home-group-article"
        v-for="itemChild in item.articleList"
        :key="itemChild.id"
        :href="`/article?id=${itemChild.id}`"
      >
        <p>{{ itemChild.title }}</p>
        <p class="home-group-article-time">{{ itemChild.createdTime }}</p>
      </a>
    </div>
  </div>
</template>

<script>
// import { Toast } from "vant";
import wxUtils from "../../utils/wxUtils";
import {
  getPosterList,
  getColumnsList,
  getArticleGroupList,
} from "./../../api/home.js";
export default {
  name: "HomePage",
  data() {
    return {
      posterList: [],
      columnsList: [],
      groupList: [],
    };
  },
  mounted() {
    this.fetchGetPosterList();
    this.fetchGetColumnsList();
    this.fetchGetArticleGroupList();
    wxUtils.init();
  },
  methods: {
    async fetchGetPosterList() {
      const result = await getPosterList();
      if (result.code === "0") {
        this.posterList = result.data;
      }
    },
    async fetchGetColumnsList() {
      const result = await getColumnsList();
      if (result.code === "0") {
        this.columnsList = result.data;
      }
    },
    async fetchGetArticleGroupList() {
      const result = await getArticleGroupList();
      if (result.code === "0") {
        this.groupList = result.data;
      }
    },
    handleClick(record) {
      if (record.turnType === 1) {
        this.$router.push(`/article?id=${record.path}`);
      } else if (record.turnType === 2) {
        this.$router.push(`/articleGroup?id=${record.path}`);
      } else if (record.path) {
        this.$router.push(record.path || "/");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.home-page {
  min-height: 100vh;
  padding-bottom: 24px;
  background-color: #fffdf2;
  // background: linear-gradient(rgba(13, 40, 155, 0.5), #fff 70%);
}
.home-banner {
  // width: calc(100% - 24px);
  // margin: 0 12px;
  // border-radius: 12px;
  // overflow: hidden;
  // border-bottom: 1px solid #f1f1f1;
}
.poster-img {
  width: 100%;
  height: 180px;
}
.home-columns {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #f1f1f1;
  border-top: 1px solid #f1f1f1;
  padding-bottom: 12px;
  > .home-columns-item {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin-top: 12px;
    > .home-columns-item-img {
      width: 48px;
      height: 48px;
      margin-bottom: 8px;
    }
    > .home-columns-item-text {
      line-height: 18px;
    }
  }
}
.home-group {
  padding: 0 8px;
  > .home-group-title {
    border-left: 4px solid #0d289b;
    padding-left: 12px;
    font-size: 16px;
    font-weight: 500;
  }
  > .home-group-article {
    border-bottom: 1px dashed rgba(13, 40, 155, 0.2);
    font-size: 14px;
    line-height: 24px;
    padding-bottom: 4px;
    margin-bottom: 8px;
    display: block;
    color: #333;
    > p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 0;
      margin-bottom: 4px;
      font-size: 15px;
    }
    > .home-group-article-time {
      color: #ccc;
      font-size: 12px;
    }
  }
}
</style>
