<!--
 * @Author: dengjianwen deng_jwen@163.com
 * @Date: 2023-06-27 04:57:39
 * @LastEditors: dengjianwen deng_jwen@163.com
 * @LastEditTime: 2023-06-27 05:12:06
 * @FilePath: /yingcai-culture-h5/src/views/Article/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="article-page">
    <p class="article-title">{{ articleInfo.title || "-" }}</p>
    <p class="article-time">{{ articleInfo.createdTime || "-" }}</p>
    <div class="article-html" v-html="articleInfo.content || '-'"></div>
  </div>
</template>

<script>
// import { Swipe, SwipeItem } from 'vant';
import { getArticleDetail } from "./../../api/home.js";
export default {
  name: "ArticlePage",
  data() {
    return {
      articleInfo: {},
    };
  },
  mounted() {
    this.fetchGetArticleDetail();
  },
  methods: {
    async fetchGetArticleDetail() {
      const result = await getArticleDetail({
        id: this.$route.query.id,
      });
      if (result.code === "0") {
        this.articleInfo = result.data;
      }
    },
  },
};
</script>
<style lang="less">
.article-page {
  padding: 0 16px;
  min-height: 100vh;
  color: #333;
  > .article-title {
    font-size: 18px;
    line-height: 32px;
    font-weight: 500;
  }
  > .article-time {
    font-size: 14px;
  }
  > .article-html {
    margin-top: 24px;
    img {
      width: 100%;
    }
  }
}
</style>
