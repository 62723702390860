<!--
 * @Author: dengjianwen deng_jwen@163.com
 * @Date: 2023-06-27 06:03:56
 * @LastEditors: dengjianwen deng_jwen@163.com
 * @LastEditTime: 2023-07-10 08:31:00
 * @FilePath: /yingcai-culture-h5/src/views/CertificateExaminer/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="certificate-student">
    <div class="certificate-student-title">新测评(皇家)证书申请</div>
    <div class="certificate-student-form">
      <van-form @submit="onSubmit"
        ><van-field name="imageUrl" label="学员照片">
          <template #input>
            <van-uploader
              :max-count="1"
              :fileList="form.imageUrl ? [{ url: form.imageUrl || '' }] : []"
              :before-read="beforeRead"
            />
          </template>
        </van-field>
        <van-field
          v-model="form.name"
          name="name"
          label="姓名"
          placeholder="姓名"
          :rules="[{ required: true, message: '请填写姓名' }]"
        >
          <template #button>
            <van-button size="small" type="info" @click.native="fetchGetPinyin"
              >获取拼音</van-button
            >
          </template>
        </van-field>
        <van-field
          v-model="form.namePy"
          name="namePy"
          label="姓名(拼音)"
          placeholder="姓名(拼音)"
          :rules="[{ required: true, message: '请填写姓名(拼音)' }]"
        />
        <van-field
          readonly
          clickable
          name="object"
          :value="form.subject"
          label="专业"
          placeholder="点击选择专业"
          @click="showObjectPicker = true"
          :rules="[{ required: true, message: '请选择专业' }]"
        />
        <van-field
          readonly
          clickable
          name="level"
          :value="form.level"
          label="级别"
          placeholder="点击选择级别"
          @click="showLevelPicker = true"
          :rules="[{ required: true, message: '请选择级别' }]"
        />
        <van-field
          name="teacher"
          v-model="form.teacher"
          label="老师"
          placeholder="老师"
          :rules="[{ required: true, message: '请填写老师' }]"
        >
          <template #button>
            <van-button
              size="small"
              type="info"
              @click.native="fetchGetTeacherPinyin"
              >获取拼音</van-button
            >
          </template>
        </van-field>
        <van-field
          name="teacherPy"
          v-model="form.teacherPy"
          label="老师(拼音)"
          placeholder="老师(拼音)"
          :rules="[{ required: true, message: '请填写老师(拼音)' }]"
        />
        <van-field
          readonly
          clickable
          name="year"
          :value="form.year"
          label="年份"
          placeholder="点击选择年份"
          @click="showYearPicker = true"
          :rules="[{ required: true, message: '请选择年份' }]"
        />
        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>
    <van-popup v-model="show"
      ><img :src="imageUrl" style="width: 350px"
    /></van-popup>

    <van-popup v-model="showSexPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="['男', '女']"
        @confirm="(value) => onConfirm(value, 'sex')"
        @cancel="showSexPicker = false"
      />
    </van-popup>
    <van-popup v-model="showYearPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="yearList.map((item) => item.name)"
        @confirm="(value) => onConfirm(value, 'year')"
        @cancel="showYearPicker = false"
      />
    </van-popup>
    <van-popup v-model="showObjectPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="objectList.map((item) => item.name)"
        @confirm="(value) => onConfirm(value, 'subject')"
        @cancel="showObjectPicker = false"
      />
    </van-popup>
    <van-popup v-model="showLevelPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="levelList.map((item) => item.name)"
        @confirm="(value) => onConfirm(value, 'level')"
        @cancel="showLevelPicker = false"
      />
    </van-popup>
  </div>
</template>
<script>
import { Toast } from "vant";
import {
  uploadFile,
  createCertificateStudentRoyalApplication,
  getPinyin,
  getObjectIdList,
  getLevelList,
  getYearList,
} from "../../api/home";
export default {
  name: "CertificateStudent",
  data() {
    return {
      certificateNo: "",
      show: false,
      imageUrl: "",
      form: {
        name: "",
        namePy: "",
        subject: "",
        level: "",
        imageUrl: "",
        teacher: "",
        teacherPy: "",
        year: "",
      },
      showSexPicker: false,
      showLevelPicker: false,
      showObjectPicker: false,
      showYearPicker: false,
      levelList: [],
      objectList: [],
      yearList: [],
    };
  },
  mounted() {
    this.fetchGetLevelList();
    this.fetchGetYearList();
    this.fetchGetObjectList();
  },
  methods: {
    async fetchGetPinyin() {
      if (this.form.name === "") {
        Toast("请输入姓名");
        return;
      }
      const result = await getPinyin({
        china: this.form.name,
      });
      if (result.code === "0") {
        this.form.namePy = result.data;
      }
    },
    async fetchGetTeacherPinyin() {
      if (this.form.teacher === "") {
        Toast("请输入老师姓名");
        return;
      }
      const result = await getPinyin({
        china: this.form.teacher,
      });
      if (result.code === "0") {
        this.form.teacherPy = result.data;
      }
    },
    async beforeRead(file) {
      if (file.type !== "image/jpeg" && file.type !== "image/jpg") {
        Toast("请上传 jpg 格式图片");
        return false;
      }
      console.log(file);
      const result = await uploadFile(file);
      if (result.code === "0") {
        this.form.imageUrl = result.data.url;
        Toast("上传成功");
      }
      return false;
    },
    async onSubmit(values) {
      console.log("submit", values);
      const result = await createCertificateStudentRoyalApplication({
        ...values,
        imageUrl: this.form.imageUrl,
        subject: this.objectList.find((item) => item.name === this.form.subject)
          .id,
        year: this.yearList.find((item) => item.name === this.form.year).id,
        level: this.levelList.find((item) => item.name === this.form.level).id,
        accountId: this.$route.params.id,
      });
      if (result.code === "0") {
        this.form = {
          name: "",
          namePy: "",
          subject: "",
          level: "",
          imageUrl: "",
          teacher: "",
          teacherPy: "",
          year: "",
        };
        Toast("提交成功");
      } else {
        Toast("提交失败");
      }
    },

    async fetchGetObjectList() {
      const result = await getObjectIdList();
      if (result.code === "0") {
        this.objectList = result.data;
      } else {
        Toast(result.msg);
      }
    },
    async fetchGetYearList() {
      const result = await getYearList();
      if (result.code === "0") {
        this.yearList = result.data;
      } else {
        Toast(result.msg);
      }
    },
    async fetchGetLevelList() {
      const result = await getLevelList();
      if (result.code === "0") {
        this.levelList = result.data;
      } else {
        Toast(result.msg);
      }
    },
    onConfirm(value, key) {
      this.form[key] = value;
      this.showSexPicker = false;
      this.showYearPicker = false;
      this.showObjectPicker = false;
      this.showLevelPicker = false;
    },
  },
};
</script>

<style lang="less" scoped>
.certificate-student {
  padding: 0 8px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.certificate-student-title {
  margin-top: 24px;
  font-size: 28px;
  font-weight: 500;
  color: #333;
}
.certificate-student-img {
  padding-top: 48px;
  width: 240px;
}
.certificate-student-form {
  padding: 24px 0;
  width: 98%;
}
</style>
