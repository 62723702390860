<template>
  <div class="certificate-student">
    <img
      class="certificate-student-img"
      src="./../../assets/hk-logo.png"
      alt=""
    />
    <div class="certificate-student-title">
      香港皇家美育藝術教育聯合(HKRAAE測評)成績查詢
    </div>
    <div class="certificate-student-form">
      <van-cell-group style="margin: 24px 0">
        <van-field
          v-model="certificateNo"
          label="证书编码"
          placeholder="请输入证书编码"
        />
        <van-field v-model="name" label="姓名" placeholder="请输入姓名" />
      </van-cell-group>
      <van-button type="info" block @click="fetchCheckCertificateNo()"
        >查 询</van-button
      >
    </div>
    <van-popup v-model="show">
      <div style="width: 350px; padding-bottom: 12px">
        <div style="line-height: 56px; text-align: center; font-size: 18px">
          查询结果
        </div>
        <van-cell-group inset>
          <van-cell title="姓名" :value="resultData.name" />
          <van-cell title="專業" :value="resultData.subject" />
          <van-cell title="級別" :value="resultData.level" />
          <van-cell title="成績" :value="resultData.result" />
          <van-cell title="老师" :value="resultData.teacher" />
          <van-cell
            title="證書編號"
            class="flex-more"
            :value="resultData.certificateNo"
          />
        </van-cell-group>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { Toast } from "vant";
import { checkCertificateStudentRoyalNo } from "../../api/home";
export default {
  name: "CertificateStudent",
  data() {
    return {
      certificateNo: "",
      name: "",
      show: false,
      imageUrl: "",
      resultData: {
        name: "",
        teacher: "",
        nationality: "",
        subject: "",
        level: "",
        result: "",
        certificateNo: "",
      },
    };
  },
  methods: {
    async fetchCheckCertificateNo() {
      if (this.certificateNo === "") {
        Toast("请输入证书编码");
        return;
      }
      if (this.name === "") {
        Toast("请输入姓名");
        return;
      }
      const result = await checkCertificateStudentRoyalNo({
        certificateNo: this.certificateNo,
        name: this.name,
      });
      if (result.code === "0") {
        this.show = true;
        this.resultData = result.data;
      } else {
        Toast("证书编码不正确");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.certificate-student {
  padding: 0 8px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.certificate-student-title {
  font-size: 20px;
  font-weight: 500;
  color: #333;
  text-align: center;
  margin: 24px 24px 0;
}
.certificate-student-img {
  padding-top: 48px;
  width: 160px;
}
.certificate-student-form {
  width: 90%;
}
</style>

<style>
.flex-more > .van-cell__value {
  flex: 2;
}
</style>
